import { configureStore } from '@reduxjs/toolkit'
import authSessionReducer from './auth'
import profileSessionReducer from './profile'
export const store = configureStore({
  reducer: {
    authSession: authSessionReducer,
    profileSession: profileSessionReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
