import { useMutation } from '@tanstack/react-query'
import {
  SoaOtpApi as SoaOtpApiClass,
  OtpVerifyReqBodyPayload,
  OtpResendReqBodyPayload,
  OtpInitReqBodyPayload,
} from '@pimy-b2cweb/apiclient-customer-auth'
// import { store } from '@/stores'
// import { selectAuthToken } from '@/stores/auth.selectors'
import { convertMask } from '@pimy-b2cweb/frontend-lib'
import { BaseApiConfig, apiClient } from './api-config'

export const SoaOtpApi = new SoaOtpApiClass(BaseApiConfig, apiClient)

export interface useMutationOtpInitProps extends OtpInitReqBodyPayload {
  recaptchaToken: string
}
export const useMutationOtpInit = () => {
  const mutationReturns = useMutation({
    mutationFn: async ({
      recaptchaToken,
      ...rest
    }: useMutationOtpInitProps) => {
      const {
        data: { body },
      } = await SoaOtpApi.init(
        {
          otpInitReqBodyPayload: rest,
        },
        {
          headers: {
            'X-Recaptcha-Token': recaptchaToken,
          },
        }
      )
      body.maskedPhoneNumber = convertMask(body.maskedPhoneNumber)
      return body
    },
    mutationKey: ['mutation-otp-init'],
  })
  return mutationReturns
}

export interface useMutationOtpResendProps extends OtpResendReqBodyPayload {}
export const useMutationOtpResend = () => {
  // let token = selectAuthToken(store.getState()) ?? {}
  const mutationReturns = useMutation({
    mutationFn: async (props: useMutationOtpResendProps) => {
      const {
        data: { body },
      } = await SoaOtpApi.resend(
        { otpResendReqBodyPayload: props }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
      return body
    },
    mutationKey: ['mutation-otp-resend'],
  })
  return mutationReturns
}

export interface useMutationOtpVerifyProps extends OtpVerifyReqBodyPayload {}
export const useMutationOtpVerify = () => {
  // let token = selectAuthToken(store.getState()) ?? {}
  const mutationReturns = useMutation({
    mutationFn: async (props: useMutationOtpVerifyProps) => {
      const {
        data: { body },
      } = await SoaOtpApi.verify(
        { otpVerifyReqBodyPayload: props }
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
      )
      return body
    },
    mutationKey: ['mutation-otp-resend'],
  })
  return mutationReturns
}
