import { Suspense } from 'react'
import { connect } from 'react-redux'
import { StatusEnum } from '@/config'
import LoadingPage from '@/pages/Loading'
import LoginPage from '@/pages/Login'
import OtpPage from '@/pages/Otp'
import DashboardPage from '@/pages/Dashboard'
import { MainBody as MainBodyUi } from '@pimy-b2cweb/frontend-lib'

export const MainBody = () => {
  return (
    <MainBodyUi>
      <Suspense fallback={<LoadingPage />}>
        <PageSwitch />
      </Suspense>
    </MainBodyUi>
  )
}

const PageSwitch = connect((state: any) => ({
  status: state.authSession.status,
}))(({ status }: { status: StatusEnum }) => {
  switch (status) {
    case StatusEnum.PendingOtp:
      return <OtpPage />
    case StatusEnum.LoggedIn:
      return <DashboardPage />
    default:
      return <LoginPage />
  }
})

export default MainBody
