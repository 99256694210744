import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProfileSessionState {
  fullName: string
  idNo: string
  phoneNumber: string
}

const initialState: ProfileSessionState = {
  fullName: '',
  idNo: '',
  phoneNumber: '',
}

export const profileSessionSlice = createSlice({
  name: 'profileSession',
  initialState,
  reducers: {
    sessionSetProfile: (state, action: PayloadAction<ProfileSessionState>) => {
      state.fullName = action.payload.fullName
      state.idNo = action.payload.idNo
      state.phoneNumber = action.payload.phoneNumber
    },
    sessionResetProfile: (state) => {
      state.fullName = initialState.fullName
      state.idNo = initialState.idNo
      state.phoneNumber = initialState.phoneNumber
    },
    sessionUpdateIdNo: (
      state,
      action: PayloadAction<{
        idNo: string
      }>
    ) => {
      state.idNo = action.payload.idNo
    },
  },
})
export default profileSessionSlice.reducer
