import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusEnum } from '@/config'

export interface AuthSessionState {
  status: StatusEnum
  token: string | null
  expiredIn: number | null
  isUserIdle: boolean | null
  maskedPhoneNumber: string | null
  challengeId: string | null
  canResend: boolean | null
}

const initialState: AuthSessionState = {
  status: StatusEnum.Unknown,
  token: null,
  expiredIn: null,
  isUserIdle: null,
  maskedPhoneNumber: null,
  challengeId: null,
  canResend: null,
}

export const authSessionSlice = createSlice({
  name: 'authSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.status = initialState.status
      state.token = initialState.token
      state.expiredIn = initialState.expiredIn
      state.isUserIdle = initialState.isUserIdle
      state.maskedPhoneNumber = initialState.maskedPhoneNumber
      state.challengeId = initialState.challengeId
      state.canResend = initialState.canResend
    },
    sessionPendingOtp: (
      state,
      action: PayloadAction<
        Omit<AuthSessionState, 'status' | 'expiredIn' | 'isUserIdle'>
      >
    ) => {
      state.status = StatusEnum.PendingOtp
      state.token = action.payload.token
      state.maskedPhoneNumber = action.payload.maskedPhoneNumber
      state.challengeId = action.payload.challengeId
      state.canResend = action.payload.canResend
    },
    renewChallengeId: (
      state,
      action: PayloadAction<{
        challengeId: string
      }>
    ) => {
      state.challengeId = action.payload.challengeId
    },
    sessionLogedin: (
      state,
      action: PayloadAction<{
        token: string
        expiredIn: number
      }>
    ) => {
      state.status = StatusEnum.LoggedIn
      state.token = action.payload.token
      state.expiredIn = action.payload.expiredIn
      state.isUserIdle = true
      state.maskedPhoneNumber = initialState.maskedPhoneNumber
      state.challengeId = initialState.challengeId
      state.canResend = initialState.canResend
    },
    setIsUserIdle: (state) => {
      state.isUserIdle = false
    },
    renewToken: (
      state,
      action: PayloadAction<{
        token: string
        expiredIn: number
      }>
    ) => {
      state.token = action.payload.token
      state.expiredIn = action.payload.expiredIn
      state.isUserIdle = true
    },
  },
})
export default authSessionSlice.reducer
