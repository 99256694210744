import { TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  ButtonWithLoading,
  FormLabel,
  ActionModal,
  useModal,
  ResponseError,
  useResponseError,
  ResponseErrorProps,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useMutationOtpVerifyProps, useMutationOtpVerify } from '@/api'
// import useNotifications from '@/hooks/useNotifications/'
import FormLayout from '@/layout/FormLayout'
import { store } from '@/stores'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { selectProfileSessionState } from '@/stores/profile.selectors'
import { authSessionSlice } from '@/stores/auth'
import { profileSessionSlice } from '@/stores/profile'
import { isNumber, getErrorResponseCode, ErrorResponseCodeEnum } from '@/utils'
import ResendErrMsg from './components/ResendErrMsg'
import useResendSms from './hooks/useResendSms'

interface OtpFormProps extends Omit<useMutationOtpVerifyProps, 'challengeId'> {}

const { sessionLogedin, sessionReset } = authSessionSlice.actions
const { sessionSetProfile } = profileSessionSlice.actions

export const OtpPage = () => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  let {
    maskedPhoneNumber,
    challengeId,
    canResend: initCanResend,
  } = selectAuthSessionState(store.getState()) ?? {}
  let { idNo } = selectProfileSessionState(store.getState()) ?? {}

  const { t } = useTranslation(['otpPage', 'errorResponse', 'common'])
  const { control, handleSubmit } = useForm<OtpFormProps>({
    mode: 'onChange',
  })
  const { modalOpen, modalClose, modalErrMsgKey, ...useErrModalRest } =
    useErrModal()
  const dispatch = useDispatch()
  // const { addNetworkErrorNotice, addNotice } = useNotifications()
  const { timeLeft, resendError, resending, onResendOtp } = useResendSms(
    initCanResend !== false
  )

  const {
    mutate,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationOtpVerify()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const onSubmit = async (data: OtpFormProps) => {
    if (!!challengeId) {
      setIsSubmitting(true)
      const mutateingData: useMutationOtpVerifyProps = {
        ...data,
        challengeId,
      }
      mutate(mutateingData)
      return
    }
    console.error('missing challengeId')
    setResponseErrorAttrs({
      i18nKey: ErrorResponseCodeEnum.InternalServerError,
      ns: 'errorResponse',
    })
  }
  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)

      if (
        [
          ErrorResponseCodeEnum.InvalidSession,
          ErrorResponseCodeEnum.TooManyAttempts,
        ].includes(errMsg)
      ) {
        modalOpen(t(`err-${errMsg}`))
      } else {
        let _errMsg: ResponseErrorProps | undefined = undefined
        switch (errMsg) {
          case ErrorResponseCodeEnum.InvalidRequest:
          case ErrorResponseCodeEnum.InvalidChallenge:
          case ErrorResponseCodeEnum.InvalidOtpCode:
            _errMsg = { i18nKey: `err-${errMsg}` }
            break
          default:
            _errMsg = { i18nKey: errMsg, ns: 'errorResponse' }
            break
        }
        setResponseErrorAttrs(_errMsg)
      }
      setIsSubmitting(false)
      return
    }
    setResponseErrorAttrs(undefined)

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(
        sessionSetProfile({
          fullName: mutatedData?.fullName,
          idNo,
          phoneNumber: mutatedData?.phoneNumber,
        })
      )
      dispatch(
        sessionLogedin({
          token: mutatedData.token,
          expiredIn: mutatedData.expiredIn,
        })
      )
    }
    if (mutateStatus !== 'loading') {
      setIsSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateStatus, mutatedData, mutatedError])

  return (
    <>
      <FormLayout
        className='otp-page'
        h1={t('h1')}
        firstP={
          <Trans
            i18nKey='firstP'
            t={t}
            values={{
              num: maskedPhoneNumber,
              idNo,
            }}
            components={{ strong: <strong /> }}
          />
        }
      >
        <ResendErrMsg resendError={resendError} />
        {!!responseErrorAttrs && (
          <ResponseError>
            {t(responseErrorAttrs.i18nKey, {
              ns: responseErrorAttrs.ns,
            })}
          </ResponseError>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-10'>
            <Controller
              name='challengeAnswer'
              control={control}
              defaultValue=''
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
                validate: (val: string) => {
                  if (!isNumber(val) || val.length !== 6) {
                    return t('invalid-input', { ns: 'common' })
                  }
                  return true
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='challengeAnswer'
                  label={t('verification-pin')}
                  required
                  isError={!!error}
                >
                  <TextField
                    type='text'
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={!!error ? (error?.message as string) : ''}
                    disabled={isSubmitting}
                    inputProps={{ maxLength: 6 }}
                    autoComplete='off'
                  />
                </FormLabel>
              )}
            />
            {resendError !== ErrorResponseCodeEnum.CanNotResendOtp && (
              <p>
                {t('didnt-receive-pin')}{' '}
                {timeLeft > 0 ? (
                  <span className='text-pi-gray-3'>
                    {
                      <Trans
                        i18nKey='resend-time'
                        t={t}
                        values={{
                          time: timeLeft / 1000,
                        }}
                      />
                    }
                  </span>
                ) : (
                  <span
                    onClick={() => onResendOtp()}
                    className={`clickable-text ${
                      isSubmitting || resending ? 'disabled' : ''
                    }`}
                  >
                    {t('resend')}
                  </span>
                )}
              </p>
            )}
          </div>
          <ButtonWithLoading
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            className='mb-6'
            disabled={isSubmitting || resending}
            isLoading={isSubmitting}
          >
            {t('continue', { ns: 'common' })}
          </ButtonWithLoading>
          <p className='text-center mb-10 sm:mb-2'>
            <Trans
              i18nKey='not-your-mobile-number'
              t={t}
              components={{ CS: <CSLink /> }}
            />
          </p>
        </form>
      </FormLayout>
      <ActionModal
        title={t('Error', { ns: 'common' })}
        actions={[
          {
            label: t('Login Screen', { ns: 'common' }),
            onClick: () => {
              dispatch(sessionReset())
              modalClose()
            },
          },
        ]}
        {...useErrModalRest}
      >
        <p>{modalErrMsgKey || null}</p>
      </ActionModal>
    </>
  )
}

const useErrModal = () => {
  const {
    modalOpen: _modalOpen,
    modalClose: _modalClose,
    ...useModalRest
  } = useModal()
  const [modalErrMsgKey, setMdalErrMsgKey] = useState<string>('')

  const modalOpen = (errMsg: string) => {
    setMdalErrMsgKey(errMsg)
    _modalOpen()
  }
  const modalClose = () => {
    _modalClose()
    setMdalErrMsgKey('')
  }

  return { modalOpen, modalClose, modalErrMsgKey, ...useModalRest }
}

export default OtpPage
