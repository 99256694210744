import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useModal } from '@pimy-b2cweb/frontend-lib'
import { authSessionSlice } from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import useSessionCountdown from './useSessionCountdown'
import useExtToken from './useExtToken'

const { sessionReset } = authSessionSlice.actions
const checkPt = 5 * 60 * 1000 //5 min in sec

export const useManageSession = () => {
  const dispatch = useDispatch()
  const onLogout = () => dispatch(sessionReset())
  const { timeleft } = useSessionCountdown()
  const { isUserIdle } = useSelector(selectAuthSessionState)
  const useModalRestReturns = useModal()
  const { modalOpen, modalClose, open } = useModalRestReturns
  const { onExtSession, mutateStatus } = useExtToken({
    onLogout,
    modalClose,
  })

  useEffect(() => {
    // console.log('count down: ', timeleft, checkPt)
    if (!!timeleft && Math.abs(timeleft - checkPt) <= 1000) {
      if (!isUserIdle) {
        // if user is not idle, call extend session
        onExtSession()
        return
      }
      if (!open) {
        // if user is idle, open extend session modal
        modalOpen()
        return
      }
    }

    if (!!timeleft && timeleft <= 1000) onLogout()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeleft])

  return {
    // timeleft,
    mmss: !!timeleft ? new Date(timeleft).toISOString().slice(14, 19) : null,
    mutateStatus,
    onLogout,
    onExtSession,
    ...useModalRestReturns,
  }
}

export default useManageSession
